import { createRouter, createWebHashHistory } from "vue-router";
import ShadeView from "../views/ShadeView/ShadeView";

const routes = [
  // {
  //   path: "/",
  //   name: "shade",
  //   component: ShadeView,
  //   meta: {
  //     title: "Melhaba",
  //   },
  // },
  {
    path: "/",
    name: "home",
    component: () =>
      import(/* webpackChunkName: "pages" */ "../views/HomeView/HomeView.vue"),
    meta: {
      title: "首页-Melhaba",
    },
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "pages" */ "../views/AboutView/AboutView.vue"
      ),
    meta: {
      title: "服务与案例-Melhaba",
    },
  },
  {
    path: "/connect",
    name: "connect",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "pages" */ "../views/ConnectView/ConnectView.vue"
      ),
    meta: {
      title: "联系我们-Melhaba",
    },
  },
  {
    path: "/build_station",
    name: "buildStation",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "pages" */ "../views/BuildStation/BuildStation.vue"
      ),
    meta: {
      title: "快速建站",
    },
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savePosition) {
    // return 期望滚动到哪个的位置
    return {
      top: 0,
    };
  },
});
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});
export default router;
